import React, { Dispatch, FC, useEffect, useState } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FileDoneOutlined } from '@ant-design/icons';
import Banners from '../../components/Banners';
import { Container } from './styles';
import OrdersTable from './OrdersTable';
import FinancialsTable from './FinancialsTable';
import { hasPermission } from '../../utils/permissions';
import { colors, ROLE } from '../../utils/constants';
import { ApplicationState } from '../../store';
import CampaignsList from './CampaignsList';
import { useHistory } from 'react-router-dom';
import ServiceOrdersTable from './ServiseOrdersTable';
import { useWindowSize } from '../../customHooks';
import { DollarClockOulined, FullBagOutlined } from '../../components/CustomIcons';
import HighlightProducts from './HighlightProducts';
import { Action } from 'typesafe-actions';
import { loadRequest } from '../../store/ducks/homeProducts/actions';
import StatisticCards from './StatisticCards';
const { TabPane } = Tabs;
const Home: FC = () => {
  const roles = useSelector((state: ApplicationState) => state.user.data?.roles);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const cards = useSelector((state: ApplicationState) => state.homeCards.data);
  const products = useSelector((state: ApplicationState) => state.homeProducts.data);
  const history = useHistory();
  const [tab, setTab] = useState<string>('1');

  const dispatch = useDispatch<Dispatch<Action>>();
  useEffect(() => {
    dispatch(loadRequest());
  }, [dispatch, token]);

  const size = useWindowSize();

  const redirectHandle = (): void => {
    switch (tab) {
      case '1':
        history.push('/home/financeiro');
        return;
      case '2':
        history.push('/home/meus-pedidos');
        return;
      case '3':
        history.push('/home/ordens-servico');
        return;
      default:
        history.push('/home');
        return;
    }
  };

  const buttonLabel = (): string => {
    switch (tab) {
      case '1':
        return 'Ver todos os registros financeiros';
      case '2':
        return 'Ver todos os pedidos';
      case '3':
        return 'Ver todas as OS';
      default:
        return 'Ver todos';
    }
  };

  return (
    <Container>
      <Banners />
      <Row gutter={[20, 20]}>
        {cards.length > 0 && (
          <Col span={24}>
            <StatisticCards />
          </Col>
        )}
        {hasPermission([ROLE.NEW_ORDER], roles) && products.length > 0 && (
          <Col span={24}>
            <HighlightProducts />
          </Col>
        )}
        {hasPermission([ROLE.CAMPAIGN], roles) && (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={
              hasPermission([ROLE.FINANCIAL], roles) ||
              hasPermission([ROLE.ORDERS], roles) ||
              hasPermission([ROLE.ORDER_SERVICE], roles)
                ? 8
                : 24
            }
          >
            <CampaignsList />
          </Col>
        )}
        {(hasPermission([ROLE.FINANCIAL], roles) ||
          hasPermission([ROLE.ORDERS], roles) ||
          hasPermission([ROLE.ORDER_SERVICE], roles)) && (
          <Col xs={24} sm={24} md={24} lg={hasPermission([ROLE.CAMPAIGN], roles) ? 16 : 24}>
            <Card
              style={{ borderRadius: '5px', boxShadow: `1px 2px 2px 2px ${colors.grey}` }}
              bodyStyle={{ padding: '0 24px', minHeight: '325px' }}
              actions={[
                <Button
                  data-cy="btn-redirect"
                  onClick={redirectHandle}
                  type="link"
                  block
                  size="small"
                >
                  {buttonLabel()}
                  <RightOutlined type="right" />
                </Button>,
              ]}
            >
              <Tabs defaultActiveKey={tab} onChange={(key) => setTab(key)}>
                {hasPermission([ROLE.FINANCIAL], roles) && (
                  <TabPane
                    tab={
                      <div data-cy="tab-overdueTitle">
                        <DollarClockOulined
                          style={{ marginLeft: size.width && size?.width <= 1024 ? '10px' : 0 }}
                        />
                        {size.width && size?.width >= 1024 && 'Títulos em atraso'}
                      </div>
                    }
                    key="1"
                  >
                    <FinancialsTable />
                  </TabPane>
                )}
                {hasPermission([ROLE.ORDERS], roles) && (
                  <TabPane
                    tab={
                      <div data-cy="tab-lastOrders">
                        <FullBagOutlined
                          style={{ marginLeft: size.width && size?.width <= 1024 ? '10px' : 0 }}
                        />
                        {size.width && size?.width >= 1024 && 'Últimos pedidos'}
                      </div>
                    }
                    key="2"
                  >
                    <OrdersTable />
                  </TabPane>
                )}
                {hasPermission([ROLE.ORDER_SERVICE], roles) && (
                  <TabPane
                    tab={
                      <div data-cy="tab-lastOS">
                        <FileDoneOutlined
                          style={{ marginLeft: size.width && size?.width <= 1024 ? '10px' : 0 }}
                        />
                        {size.width && size?.width >= 1024 && 'Últimas OS'}
                      </div>
                    }
                    key="3"
                  >
                    <ServiceOrdersTable />
                  </TabPane>
                )}
              </Tabs>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Home;
