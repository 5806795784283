import { Badge, Button, Col, Divider, Form, Row, Spin, Tooltip } from 'antd';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { Action } from 'typesafe-actions';

import { ApplicationState } from '../../../store';
import { colors } from '../../../utils/constants';
import { maskCurrency, unmask } from '../../../utils/masks';
import { TotalContainer, MessagesContainer } from './styles';
import {
  clearCart,
  saveOrderAction,
  setBudgetVisible,
  setError,
  setFields,
  totalRequest,
} from '../../../store/ducks/shoppingCart/actions';
import MessagesRender from '../MessagesRender';
import api from '../../../services/api';

interface Props {
  form: FormInstance<any>;
  onValuesChange: (_value: any, values: any) => void;
  setPdfVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Resume: React.FC<Props> = ({ form, onValuesChange, setPdfVisible }) => {
  const subTotal = useSelector((state: ApplicationState) => state.shoppingCart.subTotal);
  const result = useSelector((state: ApplicationState) => state.shoppingCart.result);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const editId = useSelector((state: ApplicationState) => state.shoppingCart.editId);
  const isBudget = useSelector((state: ApplicationState) => state.shoppingCart.budgetVisible);
  const loading = useSelector((state: ApplicationState) => state.shoppingCart.loading);
  const observationInput = useSelector(
    (state: ApplicationState) =>
      state.shoppingCart.fields.find((item) => item.key === 'observation')?.value,
    shallowEqual
  );
  const payment = useSelector(
    (state: ApplicationState) =>
      state.shoppingCart.fields.find((item) => item.key === 'payment')?.value,
    shallowEqual
  );
  const budgetValues = useSelector(
    (state: ApplicationState) =>
      state.shoppingCart.fields.filter(
        (item) => item.key !== 'observation' && item.key !== 'payment' && item.key.startsWith('creditCard.') === false
      ),
    shallowEqual
  );
  const budgetFields = useSelector((state: ApplicationState) => state.budgetFields.data);
  const address = useSelector((state: ApplicationState) => state.shoppingCart.selectedAddress);
  const cart = useSelector((state: ApplicationState) => state.shoppingCart.cart);
  const quantity = useSelector((state: ApplicationState) => state.shoppingCart.totalQuantity);
  const config = useSelector((state: ApplicationState) => state.shoppingCart.config);
  const saved = useSelector((state: ApplicationState) => state.shoppingCart.success);
  const error = useSelector((state: ApplicationState) => state.shoppingCart.error);
  const pdf = useSelector((state: ApplicationState) => state.shoppingCart.pdf);
  const errorMessage = useSelector((state: ApplicationState) => state.shoppingCart.errorMessage);
  const payments = useSelector((state: ApplicationState) => state.shoppingCart.payments);
  const { insertProductOutOfStock } = useSelector((state: ApplicationState) => state.products.config?.details || {});
  const history = useHistory();
  const dispatch = useDispatch<Dispatch<Action>>();
  const [displayErrors, setDisplayErrors] = useState<boolean>(false);
  const [haveProductOutOfStock, setHaveProductOutOfStock] = useState(false);

  const [timer, setTimer] = useState<NodeJS.Timeout | null>();

  const hasMessage = useCallback(() => {
    if (result?.validations) return true;
    if (!address) return true;
    if (config?.details.note.required && !observationInput) return true;
    if (!payment) return true;
    if (isBudget && budgetValues.length !== budgetFields.length) return true;
    if (cart.length === 0) return true;
    return false;
  }, [
    address,
    budgetFields.length,
    budgetValues.length,
    cart.length,
    config?.details.note.required,
    isBudget,
    observationInput,
    payment,
    result?.validations,
  ]);

  const cancelEdit = () => {
    Swal.fire({
      title: 'Atenção!',
      text: 'Deseja cancelar a edição do pedido?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      confirmButtonColor: layout?.color.default,
    }).then((result) => {
      if (result.isConfirmed) dispatch(clearCart());
    });
  };

  useEffect(() => {
    if (!error) {
      return;
    }
    Swal.fire({
      title: 'Erro!',
      text: errorMessage,
      icon: 'error',
      confirmButtonColor: layout?.color.default,
    }).then(() => {
      dispatch(setError(false));
    });
  }, [error, errorMessage, dispatch, layout?.color.default]);

  useEffect(() => {
    if (!saved) {
      return;
    }
    Swal.fire({
      title: `${isBudget ? 'Orçamento' : 'Pedido'} ${saved.orderId} ${editId ? 'editado' : 'realizado'
        } com sucesso!`,
      html: `<div>${saved.message || ''} <br /> ${saved.validations ? saved.validations?.map((item) => item.message) : ''
        }</div>`,
      confirmButtonColor: layout?.color.default,
      icon: saved.validations?.length > 0 ? 'warning' : 'success',
      width: '600px',
      onClose: () => {
        if (pdf) {
          setPdfVisible(true);
        }
        dispatch(clearCart());
        setFields([]);
        dispatch(setBudgetVisible(false));
        form.resetFields();
      },
    }).then(() => {
      if (pdf) {
        setPdfVisible(true);
      }
      dispatch(clearCart());
      setFields([]);
      dispatch(setBudgetVisible(false));
      form.resetFields();
    });
  }, [dispatch, editId, form, isBudget, layout?.color.default, pdf, saved, setPdfVisible]);

  const buttonLabel = (): string => {
    if (editId) return 'Editar pedido';
    if (isBudget) return 'Fazer orçamento';
    return 'Enviar pedido';
  };

  const filedsValidate = useCallback(() => {
    return (
      (isBudget && budgetValues.length !== budgetFields.length) ||
      !address ||
      !payment ||
      quantity === 0
    );
  }, [address, budgetFields, budgetValues, isBudget, payment, quantity]);

  const onFinish = async (_values: any) => {
    if (cart.length === 0) return;
    if (!address) return;
    if (!result?.amount || result?.amount < 0) return;
    if (result.blockOrder) return;
    if (!config?.details.time.validTime) return;


    const selectedPayment = payments.find(payment => payment.key === _values.payment);


    if (selectedPayment && selectedPayment.paymentMethod?.type === 'PAGARME') {
      try {
        const { data } = await api.post<{ id: string }>('https://api.pagar.me/core/v5/tokens', {
          card: {
            number: unmask(_values['creditCard.cardNumber']),
            holder_name: _values['creditCard.nameOnCard'],
            exp_month: _values['creditCard.expirationDate'].format('M'),
            exp_year: _values['creditCard.expirationDate'].format('YY'),
            cvv: _values['creditCard.cvv']
          },
          type: 'card'
        }, {
          params: {
            appId: selectedPayment.paymentMethod.publicKey
          }
        });

        dispatch(saveOrderAction(data.id));
      } catch {
        Swal.fire({
          title: 'Erro ao Validar Cartão',
          text: `Verifique os dados do cartão informado e tente novamente`,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: layout?.color.default,
          confirmButtonText: 'Verificar dados do cartão',
        })
      }
    } else {
      dispatch(saveOrderAction());
    }
  };


  const changeDelay = useCallback(
    () => {
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          dispatch(totalRequest());
        }, 500)
      );
    },
    [timer, dispatch],
  )

  useEffect(() => {
    if (filedsValidate()) {
      return;
    }

    changeDelay();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filedsValidate]);

  useEffect(() => {
    if (hasMessage()) return;
    setDisplayErrors(false);
  }, [hasMessage]);


  useEffect(() => {
    if (!insertProductOutOfStock)
      setHaveProductOutOfStock(cart.some(cartItem => cartItem.hasStock && (cartItem.quantity || 0) > (cartItem.stock || 0)));
    else
      setHaveProductOutOfStock(false)
  }, [cart, insertProductOutOfStock]);

  useEffect(() => {
    if (result && result.validations.length > 0)
      setDisplayErrors(true);
  }, [result])


  return (
    <Spin
      style={{ color: layout?.color.default }}
      spinning={loading}
      size="small"
      delay={500}
      indicator={<LoadingOutlined />}
    >
      <h2 style={{ margin: 0, color: layout?.color.dark }}>Resumo</h2>
      <Divider style={{ margin: '10px 0' }} />
      <Row gutter={[10, 10]} justify="space-between" align="middle">
        <Col>Subtotal:</Col>
        <Col>
          <strong>{maskCurrency(subTotal)}</strong>
          <Tooltip placement="left" title="Este valor não leva em conta impostos">
            <InfoCircleOutlined style={{ marginLeft: '5px', color: colors.writeGrey }} />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[10, 10]} justify="space-between" align="middle">
        {result?.freight && (
          <>
            <Col>
              <small>Entrega:</small>
            </Col>
            <Col>
              <small>
                <strong>{maskCurrency(result?.freight)}</strong>
              </small>
            </Col>
          </>
        )}
      </Row>

      {result?.amount &&
        result.amount > 0 &&
        !filedsValidate() &&
        result?.calculationSummary &&
        result.calculationSummary.length > 0 && (
          <>
            {result.calculationSummary.map((summaryItem) => {
              return (
                <>
                  {summaryItem.note ? (
                    <Row gutter={[10, 10]} justify="space-between" align="middle">
                      <Col>
                        <small>{summaryItem.note}</small>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      {summaryItem.description && (
                        <Row gutter={[10, 10]} justify="space-between" align="middle">
                          <Col>
                            <small>{summaryItem.description}:</small>
                          </Col>
                          <Col>
                            <small>
                              <strong>{maskCurrency(summaryItem?.value || 0)}</strong>
                            </small>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </>
              );
            })}
          </>
        )}

      {result?.amount && result.amount > 0 && !filedsValidate() && (
        <TotalContainer validValue={!result.blockOrder}>
          <Row justify="space-between" align="middle">
            <Col>Total do pedido:</Col>
            <Col>
              <h2 style={{ color: !result.blockOrder ? '#5b8c00' : '#FF4D4F', margin: 0 }}>
                <strong>{maskCurrency(result?.amount)}</strong>
              </h2>
            </Col>
          </Row>
        </TotalContainer>
      )}
      {((result?.validations?.some((item) => item.validation === 'WARNING') &&
        payment &&
        address) ||
        displayErrors) && (
          <MessagesContainer>
            <Row>
              {result?.validations?.some((item) => item.validation === 'WARNING') &&
                payment &&
                address && (
                  <>
                    {result.validations
                      ?.filter((item) => item.validation === 'WARNING')
                      ?.map((item) => {
                        return (
                          <Col span={24}>
                            <Badge status={'warning'} text={item.message} />
                          </Col>
                        );
                      })}
                  </>
                )}
              {displayErrors && <MessagesRender />}
            </Row>
          </MessagesContainer>
        )}
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Form layout="vertical" form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
            {haveProductOutOfStock ?
              (<Tooltip placement="left" title="Você possui produtos sem estoque no carrinho">
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  disabled
                >
                  {buttonLabel()}
                </Button>
              </Tooltip>) : (<Form.Item style={{ margin: 0 }}>
                <Button
                  data-cy="btn-placeOrder"
                  type="primary"
                  block
                  htmlType="submit"
                  onClick={() => setDisplayErrors(hasMessage())}
                >
                  {buttonLabel()}
                </Button>
              </Form.Item>)}
          </Form>
        </Col>
        {editId && (
          <Col span={24}>
            <Button type="default" block onClick={cancelEdit}>
              Cancelar edição
            </Button>
          </Col>
        )}
        <Col span={24}>
          <Button type="link" block onClick={() => history.push('/home/novo-pedido')}>
            Continuar comprando
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

export default Resume;
